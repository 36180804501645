import { Box, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import Sidebar from "../../common/Sidebar/Sidebar";
import Merge from "./Merge";
import {
  FaArrowsAlt,
  FaCodeBranch,
  FaFileImport,
  FaLink,
  FaSearch,
} from "react-icons/fa";
import { Routes, Route, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

const EventsHome = () => {
  const location = useLocation();

  // Check if "qa" exists in the current URL
  const isQA = window.location.href.includes("qa");

  const subMenu = [
    {
      icon: <Icon as={FaCodeBranch} />,
      path: "",
      label: "Merge Events",
    },
    {
      icon: <Icon as={FaArrowsAlt} />,
      path: "moveEvents",
      label: "Move Events",
    },
    {
      icon: <Icon as={FaLink} />,
      path: "mapShEvent",
      label: "Map Stubhub Event",
    },
    {
      icon: <Icon as={FaFileImport} />,
      path: "alfredtmlink",
      label: "Link Alfred to Ticketmaster Event",
    },
    {
      icon: <Icon as={FaArrowsAlt} />,
      path: "transferInventory",
      label: "Transfer inventory",
    },
    {
      icon: <Icon as={FaSearch} />,
      path: "eventsearch",
      label: "Viagogo Event Search",
    },
    {
      icon: <Icon as={FaArrowUpRightFromSquare} />,
      path: isQA
        ? "https://event-mapping-qa.stagefront.com/"
        : "https://event-mapping.stagefront.com/",
      label: "Event Mapping Tool",
    },
  ];

  return (
    <Flex direction={"row"}>
      <Sidebar isOpen={true} title={"Event Management"} linkItems={subMenu} />
      <Box h="full" p="4">
        <Flex direction={"column"}>
          <Routes>
            <Route path="/" element={<Merge />} />
          </Routes>
          <Outlet />
        </Flex>
      </Box>
    </Flex>
  );
};

export default EventsHome;
